<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        cols="12"
      >
        <analytics-statistics :data="dataEcommerce.statisticsItems" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import AnalyticsStatistics from './AnalyticsStatistics.vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsStatistics,
  },
  data() {
    return {
      data: {},
      dataEcommerce: {},
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })

    this.$http.get('/ecommerce/data')
      .then(response => { this.dataEcommerce = response.data })
  },
  methods: {
    kFormatter,
  },
}
</script>
